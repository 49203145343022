<!-- 只样品单 签正式订单 表单页-->
<template>
    <div class="container">
        <div class="container_form">
            <div class="container_form_top">Sign Formal Order</div>

            <div class="container_form_content">
                <el-form :model="forms">
                    <!-- Product Name -->
                    <el-form-item class="el-btn" label="Formal Num" prop="formalNum">
                        <el-input v-model="forms.formalNum" placeholder="Enter Formal Num"></el-input>
                    </el-form-item>

                    <el-form-item class="el-btn">
                        <el-button type="primary" @click="submitForm">Confirm</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- foot   -->
        <Foot />
    </div>

</template>

<script setup >
import { reactive, ref, toRefs } from '@vue/reactivity'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import Foot from '../../components/Foot.vue'
import { signFormalOrder } from '@/api/order'
const route = useRoute()
const router = useRouter()
const orderId = route.query.orderId

// 表单数据
const forms = ref({
  formalNum: ''
})

const submitForm = async () => {
  console.log(forms.value)
  forms.value.orderId = orderId
  if (forms.value.formalNum == '') {
    ElMessage.error('please input num')
    return
  }
  const res = await signFormalOrder(forms.value)
  if (res.code === 200) {
    router.push({
      path: 'home'
    })
  }
}

</script>

<style lang="scss" scoped>
@use '../../assets/scss/elForm.scss' as *;

.container {
    text-align: left;
    line-height: 1;

    &_form {
        margin: 0 auto 200px;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        width: 1200px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        text-align: center;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;

        &_top {
            line-height: 100px;
            background-color: rgba(39, 174, 96, 0.04);
            text-align: center;
            color: rgba(39, 174, 96, 1);
            font-size: 20px;
            font-weight: bold;
        }

        &_content {
            width: 730px;
            margin: 40px auto;
            display: flex;
            flex-direction: column;

            .el-item-line {
                width: 100%;
                height: 1px;
                background-image: linear-gradient(to right, rgba(39, 174, 96, 1) 0%, rgba(39, 174, 96, 1) 50%, transparent 50%);
                background-size: 10px 1px;
                background-repeat: repeat-x;
                margin-top: 20px;
                margin-bottom: 50px;
            }

            .address {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                width: 350px;
                height: 52px;
                color: rgba(39, 174, 96, 1);
            }

            .el-upload__text {
                em {
                    color: rgba(39, 174, 96, 1);
                }
            }

            .el-btn {
                width: 730px !important;
                display: flex;
                align-items: center;
                margin-top: 30px;

                :deep .el-form-item__content {
                    width: auto;
                }
            }
        }
    }

}
</style>
<style lang="scss">
.el-dialog {
    border-radius: 8px;
    overflow: hidden;

    .el-dialog__header {
        padding: 0 !important;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(248, 248, 248, 1);

        .el-dialog__headerbtn {
            position: static;
        }

    }
}

.my-header {
    height: 80px;
    line-height: 80px;
    border-radius: 8px 8px 0px 0px;
    background-color: rgba(248, 248, 248, 1);
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;

    &_title {
        margin-left: 30px;
        line-height: 80px;
        color: rgba(24, 24, 24, 1);
        font-size: 20px;
        text-align: left;
        font-weight: bold;
    }
}

.el-form {
    align-items: center;

    .el-form-item {
      align-items: center;
        width: 350px !important;

        .el-form-item__label {
            width: 320px !important;
        }

        .el-form-item__content .el-input {
            width: 350px;
        }

        .el-upload {
            width: 350px;

            .el-upload-dragger {
                padding: 20px 0;
            }
        }
    }
}

.el-input-group {
    margin-bottom: 30px;

    .el-input-group__prepend {
        background-color: rgba(249, 249, 249, 1);
        box-shadow: none;

        .select-trigger {
            .el-input {
                border-color: aquamarine;
                --el-input-border-color: rgba(229, 229, 229, 1);

                .el-input__wrapper {
                    height: 46px;
                    padding: 0px 20px !important;
                    // box-shadow: none;
                    border-right: 1px solid rgba(229, 229, 229, 1);
                }
            }

        }
    }

    .el-input-group__prepend .el-select .el-input {
        .el-input__wrapper {
            box-shadow: none;

            &:hover {
                box-shadow: none;
            }
        }
    }

    .is-focus .el-input__wrapper.is-focus {
        box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
        border: 1px solid rgba(39, 174, 96, 1);
        box-sizing: border-box;
    }

    .el-input__wrapper {
        padding: 0 20px;
        background-color: rgba(249, 249, 249, 1);
        box-shadow: none;
    }

    .is-focus {
        box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
        border: 1px solid rgba(39, 174, 96, 1);
        box-sizing: border-box;
    }
}
</style>
